<!-- eslint-disable no-useless-escape -->
<template>
  <v-form class="px-5" ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="form.title"
          :items="titleList"
          item-text="value"
          item-value="value"
          label="Title"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          ref="firstName"
          v-model="form.firstName"
          label="First Name"
          :rules="nameValidation"
          @input="firstNameCapitalLetter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-text-field
      ref="lastName"
      v-model="form.lastName"
      label="Surname"
      :rules="surnameValidation"
      required
      @input="lastNameCapitalLetter"
    ></v-text-field>

    <v-text-field
      ref="jobTitle"
      v-model="form.jobTitle"
      label="Job Title"
      :rules="jobTitleValidation"
      required
      @input="jobTitleCapitalLetter"
    ></v-text-field>
    <!-- <base-telephone-input ref="contactNumber"  :required="telehponeRequired" refName="contactNumber" label="Telephone" :error-msg-c-c="errorMsgCC" v-model="form.contactNumber" /> -->
    <!-- <base-telephone-input :required="false" label="Alternative Telephone" :error-msg-c-c="errorMsg" v-model="form.altContactNumber" /> -->

    <v-text-field
      ref="Telephone"
      v-model="form.contactNumber"
      label="Telephone"
      :rules="contactNumberRules"
    ></v-text-field>
    <label v-if="telephoneExistError" for="telehpone" class="error--text">
      {{ telephoneExistErrorMessage }}
    </label>
    <v-text-field
      v-model="form.emailAddress"
      label="Email"
      required
      :rules="emailValidation"
    ></v-text-field>

    <div class="mt-4">
      <v-btn
        depressed
        color="accent"
        @click="validateContactAndSave"
        block
        dark
        large
      >
        {{ formEditMode ? "Save changes" : "Add Contact" }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { mapActions, mapGetters } from "vuex"
import { getContactState } from "@/store/helpers/default-state"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

// import BaseTelephoneInput from "@/components/common/BaseTelephoneInput"

// import { required } from "@/helpers/form-validations"

export default {
  components: {
    // BaseTelephoneInput
  },
  data: () => ({
    valid: true,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    contactNumber: null,
    isSendingRequest: false,
    form: {
      title: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      contactNumber: "",
      // altContactNumber:
      emailAddress: "",
    },
    formHasErrors: false,
    errorMsg: "",
    errorMsgCC: "",
    showMessage: false,
    telephoneExistError: false,
    telephoneExistErrorMessage: "",
  }),
  computed: {
    ...mapGetters("forms", ["titleList"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("contacts", ["selectedContact"]),
    ...mapGetters("company", ["companyId"]),
    ...mapGetters("employee", ["userId"]),

    contactNumberRules() {
      // if(!this.value) {
      //     return [
      //         (v) => !!v || "Field is required"
      //     ]
      // }
      return [
        (v) => {
          const validate = /^(00|034|084|087|04|05|06|09)/.test(v)
          return (
            !validate ||
            "Numbers Starting With '00, 034, 084, 087, 04, 05, 06, 09' Are Not Allowed"
          )
        },
        (v) => !!v || "Field is required",
        // (v) => /[0-9]+$/.test(v) || "Only Numeric Characters Allowed.",
        (v) => v.startsWith(0) || "Phone Number must start with 0.",
        (v) =>
          (v && v.length > 9) || "Phone Number must have at least 10 digits.",
        // v => /(00|034|084|087|04|05|06|09)+$/.test(v) || "Numbers Starting With '00, 034, 084, 087, 04, 05, 06, 09' Are Not Allowed",
      ]
    },

    nameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    surnameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    jobTitleValidation() {
      return [(v) => /[a-zA-Z ]+$/.test(v) || "Field must only contain letters"]
    },
    emailValidation() {
      return [
        (v) => {
          return (
            // eslint-disable-next-line no-useless-escape
            /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || "Must be valid email"
          )
        },
      ]
    },
    // telephoneValidation() {
    //   return [(v) => /^[0-9]+$/.test(v) || "Field must only contain Numbers"]
    // },
  },
  watch: {
    selectedContact() {
      this.isSendingRequest = false
      this.formHasErrors = false
      this.errorMsg = ""
      this.errorMsgCC = ""
      this.$refs.form.resetValidation()
      this.bindContactDetails()
      this.showMessage = false
    },
    formEditMode(value) {
      this.isSendingRequest = false
      this.formHasErrors = false
      this.errorMsg = ""
      this.errorMsgCC = ""
      this.showMessage = false
      this.$refs.form.resetValidation()
      if (!value) {
        this.$refs.form.reset()
        this.form.firstName = "Unknown"
      } else {
        this.bindContactDetails()
      }
    },
  },
  created() {
    this.bindContactDetails()
    this.fetchTitles()
  },
  mounted() {},
  methods: {
    ...mapActions("ui", ["closeForm"]),
    ...mapActions("forms", ["fetchTitles", "fetchMainData"]),
    ...mapActions("contacts", [
      "updateContact",
      "createContact",
      "fetchContacts",
    ]),
    ...mapActions("notes", ["fetchNotes"]),
    isNumberKey(evt) {
      this.showInvalidFormatError = false
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        event.returnValue = true
        return true
      }
    },
    jobTitleCapitalLetter() {
      this.form.jobTitle = this.form.jobTitle
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    lastNameCapitalLetter() {
      this.form.lastName = this.form.lastName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    firstNameCapitalLetter() {
      this.form.firstName = this.form.firstName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    validateContactAndSave() {
      if (this.form.contactNumber) {
        const contactNumber2 = this.form.contactNumber

        const validatePattern = /^(00|034|084|087|04|05|06|09)/
        const validateStartsWithZero = contactNumber2.startsWith("0")
        const validateLength = contactNumber2.length >= 10
        if (contactNumber2.length === 0) {
          console.log("here")
        } else if (
          validatePattern.test(contactNumber2) ||
          !validateStartsWithZero ||
          !validateLength
        ) {
          return
        }
      }

      // this.form.jobTitle= this.form.jobTitle.replace(/\s/g, "");
      this.form.jobTitle = this.form.jobTitle.trim()

      const contactNumber = this.form.contactNumber
        ? this.form.contactNumber
        : ""
      const firstName = this.form.firstName ? this.form.firstName : "Unknown"
      const lastName = this.form.lastName
      const jobTitle = this.form.jobTitle
      // console.log(this.$refs.contactNumber.$refs['contactNumber'])
      if (
        this.formEditMode &&
        (!/[a-zA-Z]+$/.test(firstName) ||
          this.firstName === "" ||
          (lastName !== "" && !/[a-zA-Z]+$/.test(lastName)) ||
          (jobTitle !== "" && !/[a-zA-Z]+$/.test(jobTitle)))
      ) {
        console.log(3)

        const form = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          jobTitle: "",
        }
        console.log(4)

        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        console.log(5)

        return false
      }
      console.log(6)

      if (
        this.formEditMode &&
        contactNumber &&
        contactNumber !== "" &&
        (contactNumber.length < 10 ||
          /^(00|034|084|087|04|05|06|09)/.test(contactNumber))
      ) {
        this.$refs.contactNumber.$refs["contactNumber"].validate(true)
        return false
      }
      console.log(7)

      if (
        !this.formEditMode &&
        (!/[a-zA-Z]+$/.test(firstName) || this.firstName === "")
      ) {
        const form = {
          firstName: this.form.firstName ? this.form.firstName : "Unknown",
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }

      console.log(8)

      this.submitForm()
    },

    // -------Lets separate the function Update and edit Contactac -----
    submitForm() {
      this.telephoneExistError = false
      if (!this.formEditMode) {
        this.addContact()
      } else {
        this.editContact()
      }
    },
    async addContact() {
      if (
        !this.form.firstName &&
        !this.form.lastName &&
        !this.form.jobTitle &&
        !this.form.contactNumber &&
        !this.form.emailAddress
      ) {
        this.$refs.form.validate()
        return false
      } else {
        this.$refs.form.resetValidation()
      }

      if (this.isSendingRequest) {
        return false
      }
      this.isSendingRequest = true
      this.form.firstName = this.form.firstName
        ? this.form.firstName
        : "Unknown"
      const defaultContact = getContactState()
      const noteMessage = `Contact Added - ${this.form.title} ${this.form.firstName} ${this.form.lastName}<br>
                          ${this.form.jobTitle}<br>
                          ${this.form.contactNumber}<br>
                          ${this.form.emailAddress}`
      const res = await this.createContact({
        ...defaultContact,
        ...this.form,
        companyId: this.companyId,
        noteMessage: noteMessage,
        currentUserId: this.userId,
      })
      const self = this

      if (res.status === 200) {
        this.isSendingRequest = true

        if (isNaN(parseFloat(res.data))) {
          this.showMessage = true
          if (res.data.includes("alternate")) {
            self.errorMsg = res.data
          } else {
            self.errorMsgCC = res.data
            setTimeout(() => {
              this.showMessage = false
            }, 3000)
          }
          this.isSendingRequest = false
          this.telephoneExistError = true
          this.telephoneExistErrorMessage = res.data
        } else {
          changesSaved("Contact Added Sucessfully")
          this.fetchMainData(this.companyId)
          this.fetchContacts()
          this.fetchNotes()
          this.contactNumber = null
          this.isSendingRequest = false
          this.form = {
            title: "",
            firstName: "",
            lastName: "",
            jobTitle: "",
            contactNumber: "",
            emailAddress: "",
          }
          this.formHasErrors = false
          this.errorMsg = ""
          this.errorMsgCC = ""
          this.$refs.form.resetValidation()
          this.closeForm()
        }
      }
    },
    async editContact() {
      // const noteMessage = `Contact Updated - ${this.form.title} ${this.form.firstName} ${this.form.lastName}`
      if (
        !this.form.firstName &&
        !this.form.lastName &&
        !this.form.jobTitle &&
        !this.form.contactNumber &&
        !this.form.emailAddress
      ) {
        this.$refs.form.validate()
        return false
      } else {
        this.$refs.form.resetValidation()
      }

      let noteMessage = ""

      if (this.form.title !== this.selectedContact.title) {
        noteMessage += `Title changed from ${
          this.selectedContact.title ? this.selectedContact.title : ""
        } to ${this.form.title}<br>`
      }

      if (this.form.firstName !== this.selectedContact.firstName) {
        noteMessage += `First Name changed from ${
          this.selectedContact.firstName ? this.selectedContact.firstName : ""
        } to ${this.form.firstName}<br>`
      }

      if (this.form.lastName !== this.selectedContact.lastName) {
        noteMessage += `Last Name changed from ${
          this.selectedContact.lastName ? this.selectedContact.lastName : ""
        } to ${this.form.lastName}<br>`
      }

      if (this.form.jobTitle !== this.selectedContact.jobTitle) {
        noteMessage += `Job Title changed from ${
          this.selectedContact.jobTitle ? this.selectedContact.jobTitle : ""
        } to ${this.form.jobTitle}<br>`
      }

      if (this.form.contactNumber !== this.selectedContact.contactNumber) {
        noteMessage += `Contact Number changed from ${
          this.selectedContact.contactNumber
            ? this.selectedContact.contactNumber
            : ""
        } to ${this.form.contactNumber}<br>`
      }

      if (this.form.emailAddress !== this.selectedContact.emailAddress) {
        noteMessage += `Email changed from ${
          this.selectedContact.emailAddress
            ? this.selectedContact.emailAddress
            : ""
        } to ${this.form.emailAddress}<br>`
      }

      this.form.firstName = this.form.firstName
        ? this.form.firstName
        : "Unknown"
      const res = await this.updateContact({
        ...this.selectedContact,
        ...this.form,
        companyId: this.companyId,
        noteMessage:
          noteMessage === ""
            ? ""
            : `Contact Updated - ${this.form.title} ${this.form.firstName} ${this.form.lastName}<br>${noteMessage}`,
        currentUserId: this.userId,
        leadId: this.selectedContact.leadId ? this.selectedContact.leadId : 0,
        securityPassword: this.selectedContact.securityPassword
          ? this.selectedContact.securityPassword
          : "",
      })
      const self = this
      if (res.status === 200) {
        if (isNaN(parseFloat(res.data))) {
          this.showMessage = true

          if (res.data.includes("alternate")) {
            self.errorMsg = res.data
          } else {
            self.errorMsgCC = res.data
            setTimeout(() => {
              this.showMessage = false
            }, 3000)
          }
          somethingWentWrong()
        } else {
          changesSaved("Contact Successfully Edited")
          this.fetchNotes()
          this.fetchMainData(this.companyId)
          this.fetchContacts()
          this.closeForm()
        }
      }
    },

    bindContactDetails() {
      if (this.formEditMode && this.selectedContact) {
        Object.entries(this.selectedContact).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    },
  },
}
</script>
